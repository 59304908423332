<template>
    <v-popover
        trigger="hover"
        placement="left-start"
        offset="1"
        :delay="{
            hide:300
        }"
        :auto-hide="false"
        class="submenu"
        popoverInnerClass="submenu__body"
    >
        <span class="submenu__icon tooltip-target"/>

        <template slot="popover" class="submenu__content">
            <ul class="submenu__links">
                <li class="submenu__item">
                    <a href="javascript:void(0)" @click="onEdit">Редактировать</a>
                </li>
                <li class="submenu__item">
                    <a href="javascript:void(0)" @click="() => onDelete(postId)">Удалить</a>
                </li>
            </ul>
        </template>
    </v-popover>
</template>

<script>
    export default {
        name: "Submenu",
        props: {
            onDelete: {
                type: Function,
                required: true
            },
            onEdit: {
                type: Function,
                required: true
            },
            postId: {
                type: Number,
                required: true
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"
    .trigger
        display: flex !important
    .submenu
        cursor: pointer
        &__icon
            display: flex
            background-image: $submenu-icon
            background-repeat: no-repeat
            background-position: center
            background-size: contain
            width: 18px
            height: 18px
        &__links
            padding: 0
            list-style-type: none
            width: 100%
            margin-bottom: 0
        &__item
            &:hover a
                color: rgba(87, 87, 87, 0.7)
            a
                padding: 5px 15px
                height: 100%
                width: 100%
                display: flex
                color: rgba(87, 87, 87, 0.9)
                &:first-child
                    padding-top: 10px
                &:last-child
                    padding-bottom: 10px
</style>
